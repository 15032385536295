<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="1200px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
    >
      <el-form
        ref="airFormRef"
        :inline="true"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="产品编号" prop="productNo">
          <el-select
            v-model="airForm.productNo"
            placeholder="请选择产品"
            clearable
            filterable
            @change="materialProductStockChange"
          >
            <el-option
              v-for="item in materialProductStockList"
              :key="item.id"
              :label="item.productNo"
              :value="item.productNo"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称" prop="productName">
          <el-input
            v-model="airForm.productName"
            placeholder="请输入产品名称"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="规格" prop="spec">
          <el-input
            v-model="airForm.spec"
            placeholder="请输入规格"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="批号" prop="batchNumber">
          <el-input
            v-model="airForm.batchNumber"
            placeholder="请输入批号"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="工序" prop="process">
          <!-- <el-input
            v-model="airForm.process"
            placeholder="请输入工序"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          /> -->
          <el-select v-model="airForm.process" placeholder="请选择工序" clearable>
            <el-option
              v-for="item in ['清洗','焊接（关键）','组装热合（关键）','整件检验','装PE袋','封小包装（特殊）','组装','黏贴海绵','装箱']"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="清场时间" prop="clearanceEndTime">
          <el-date-picker
            v-model="runningTime"
            :readonly="handleType !== 'add' && handleType !== 'update'"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            range-separator="至"
            start-placeholder="清场开始时间"
            end-placeholder="清场结束时间"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="productFormSubmit">
          确 定
        </el-button>
      </div>
      <section>
        <!-- <el-button
          v-if="handleType === 'update'"
          type="primary"
          @click="handleAdd2"
        >
          新 增
        </el-button> -->
        <el-table
          :data="airForm.detailList"
          style="width: 100%"
          :span-method="objectSpanMethod"
        >
          <el-table-column label="序号" type="index" />
          <el-table-column
            prop="clearanceProject"
            label="清场项目"
            align="center"
          />
          <el-table-column
            prop="clearanceResult"
            label="清场结果"
            align="center"
          >
            <template slot-scope="scope">
              <p v-if="handleType == 'detail'">
                {{ scope.row.clearanceResult === 1 ? "符合要求" : "不符合" }}
              </p>
              <el-radio-group
                v-else
                v-model="scope.row.clearanceResult"
              >
                <el-radio :label="1">
                  符合要求
                </el-radio>
                <el-radio :label="2">
                  不合格
                </el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="操作"
            align="center"
            v-if="handleType != 'add'"
          >
            <template slot-scope="scope">
              <el-button
                v-if="handleType === 'update'"
                type="text"
                icon="el-icon-delete"
                size="small"
                @click.stop="handleDelete2(scope.$index, airForm.detailList)"
              >
                删除
              </el-button>
              <el-button
                v-if="handleType === 'update'"
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="handleUpdate2(scope.$index, scope.row)"
              >
                修改
              </el-button>
              <el-button
                type="text"
                icon="el-icon-info"
                size="small"
                @click.stop="handleInfo2(scope.$index, scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            label="操作人"
            align="center"
            v-if="handleType != 'add'"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.operator }}</p>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            label="复核人"
            align="center"
            v-if="handleType != 'add'"
          >
            <template slot-scope="scope">
              <el-button
                v-if="!scope.row.reviewer"
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="handleReview(airForm.id, scope.row.id)"
              >
                复核
              </el-button>
              <p>{{ scope.row.reviewer }}</p>
            </template>
          </el-table-column> -->
        </el-table>
        <el-table
          :data="[airForm]"
          style="width: 100%"
          :show-header="false"
          :span-method="objectSpanMethod2"
        >
          <el-table-column>
            <p>检查人/日期</p>
          </el-table-column>
          <el-table-column align="center">
            <el-button
              v-if="airForm.checkStatus === 1"
              type="text"
              icon="el-icon-edit"
              size="small"
              @click.stop="handleCheck(airForm.id)"
            >
              检查
            </el-button>
            <p v-else>
              {{ airForm.checker }}/{{ airForm.checkTime }}
            </p>
          </el-table-column>
          <el-table-column align="center" />
          <el-table-column align="center" />
          <el-table-column align="center" />
          <el-table-column align="center" />
        </el-table>
        <!-- <div slot="footer">
          <el-button @click="airDialogVisible = false">关闭</el-button>
        </div> -->
      </section>
      <el-dialog
        width="60%"
        title="新增操作记录"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          ref="productFormRef"
          :model="airDetailForm"
          :rules="productFormRules"
          label-position="right"
          label-width="150px"
        >
          <el-form-item label="清场项目" prop="clearanceProject">
            <el-input
              v-model="airDetailForm.clearanceProject"
              placeholder="请输入清场项目"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="清场结果" prop="clearanceResult">
            <el-radio-group
              v-model="airDetailForm.clearanceResult"
              :disabled="airDetailForm.inputType === 'detail'"
            >
              <el-radio :label="1">
                符合要求
              </el-radio>
              <el-radio :label="2">
                不合格
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="innerVisible = false">
            取 消
          </el-button>
          <el-button type="primary" @click="productFormSubmit2">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <el-dialog
      :title="airFormTitle"
      width="1200px"
      :visible.sync="airDialogVisible2"
      :close-on-click-modal="false"
      @close="airDialogClose"
    >
      <el-form
        ref="airFormRef"
        :inline="true"
        :model="airForm"
        :rules="productFormRules3"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="生产车间" prop="productionWorkshop">
          <el-input
            v-model="airForm.productionWorkshop"
            placeholder="请输入产品名称"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="工序" prop="process">
          <el-input
            v-model="airForm.process"
            placeholder="请输入工序"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="房间号" prop="roomNo">
          <el-input
            v-model="airForm.roomNo"
            placeholder="请输入房间号"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="产品名称" prop="productName">
          <el-input
            v-model="airForm.productName"
            placeholder="请输入产品名称"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="批号" prop="batchNumber">
          <el-input
            v-model="airForm.batchNumber"
            placeholder="请输入批号"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="清场人" prop="cleaner">
          <el-input
            v-model="airForm.cleaner"
            placeholder="请输入清场人"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="清场时间" prop="clearanceTime">
          <el-date-picker
            v-model="airForm.clearanceTime"
            type="datetime"
            placeholder="清场时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="检查人" prop="checker">
          <el-input
            v-model="airForm.checker"
            placeholder="请输入检查人"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="检查时间" prop="checkTime">
          <el-date-picker
            v-model="airForm.checkTime"
            type="datetime"
            placeholder="检查时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="签发人" prop="signer">
          <el-input
            v-model="airForm.signer"
            placeholder="请输入签发人"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="签发时间" prop="signTime">
          <el-date-picker
            v-model="airForm.signTime"
            type="datetime"
            placeholder="签发时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="有效期" prop="validPeriod">
          <el-date-picker
            v-model="airForm.validPeriod"
            type="datetime"
            placeholder="签发时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible2 = false">
          取 消
        </el-button>
        <el-button type="primary" @click="productFormSubmit3">
          确 定
        </el-button>
      </div>
      <section v-if="handleType != 'add'">
        <!-- <el-button
          v-if="handleType === 'update'"
          type="primary"
          @click="handleAdd2"
        >
          新 增
        </el-button> -->
        <el-table
          :data="airForm.detailList"
          style="width: 100%"
          :span-method="objectSpanMethod"
        >
          <el-table-column label="序号" type="index" />
          <el-table-column
            prop="clearanceProject"
            label="清场项目"
            align="center"
          />
          <el-table-column
            prop="clearanceResult"
            label="清场结果"
            align="center"
          >
            <template slot-scope="scope">
              <p>
                {{ scope.row.clearanceResult === 1 ? "符合要求" : "不符合" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                v-if="handleType === 'update'"
                type="text"
                icon="el-icon-delete"
                size="small"
                @click.stop="handleDelete2(scope.$index, airForm.detailList)"
              >
                删除
              </el-button>
              <el-button
                v-if="handleType === 'update'"
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="handleUpdate2(scope.$index, scope.row)"
              >
                修改
              </el-button>
              <el-button
                type="text"
                icon="el-icon-info"
                size="small"
                @click.stop="handleInfo2(scope.$index, scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作人" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.operator }}</p>
            </template>
          </el-table-column>
          <el-table-column label="复核人" align="center">
            <template slot-scope="scope">
              <el-button
                v-if="!scope.row.reviewer"
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="handleReview(airForm.id, scope.row.id)"
              >
                复核
              </el-button>
              <p>{{ scope.row.reviewer }}</p>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          :data="[airForm]"
          style="width: 100%"
          :show-header="false"
          :span-method="objectSpanMethod2"
        >
          <el-table-column>
            <p>检查人</p>
          </el-table-column>
          <el-table-column align="center">
            <el-button
              v-if="airForm.checkStatus === 1"
              type="text"
              icon="el-icon-edit"
              size="small"
              @click.stop="handleCheck(airForm.id)"
            >
              检查
            </el-button>
            <p v-else>
              {{ airForm.checker }}/{{ airForm.checkTime }}
            </p>
          </el-table-column>
          <el-table-column align="center" />
          <el-table-column align="center" />
          <el-table-column align="center" />
          <el-table-column align="center" />
        </el-table>
        <!-- <div slot="footer">
          <el-button @click="airDialogVisible = false">关闭</el-button>
        </div> -->
      </section>
      <el-dialog
        width="60%"
        title="新增操作记录"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          ref="productFormRef"
          :model="airDetailForm"
          :rules="productFormRules"
          label-position="right"
          label-width="150px"
        >
          <el-form-item label="清场项目" prop="clearanceProject">
            <el-input
              v-model="airDetailForm.clearanceProject"
              placeholder="请输入清场项目"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="清场结果" prop="clearanceResult">
            <el-radio-group
              v-model="airDetailForm.clearanceResult"
              :disabled="airDetailForm.inputType === 'detail'"
            >
              <el-radio :label="1">
                符合要求
              </el-radio>
              <el-radio :label="2">
                不合格
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="innerVisible = false">
            取 消
          </el-button>
          <el-button type="primary" @click="productFormSubmit2">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input
          v-model="searchForm.batchNumber"
          placeholder="请输入批号"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="airList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="batchNumber" label="批号" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="clearanceStartTime" label="清场开始时间" />
      <el-table-column prop="clearanceEndTime" label="清场结束时间" />
      <el-table-column prop="operator" label="操作人" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <!-- <el-button
            type="text"
            icon="el-icon-plus"
            size="small"
            @click.stop="handleGenerate( scope.row.id)"
          >
            生成合格证
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="airList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getClearanceList,
  deleteClearanceList,
  addClearanceList,
  addClearanceInfoDetail,
  generateClearanceList,
  getClearanceDetail,
  updateClearanceDetail,
  checkClearanceList,
  reviewClearanceList
} from '@/api/produce/produce.js'
import { selectMaterialProductStockList } from '@/api/storage/materialProductStock'

export default {
  data () {
    return {
      airDialogVisible: false,
      airDialogVisible2: false,
      innerVisible: false,
      airFormTitle: '',
      airForm: {},
      productPage: {
        list: [],
        total: 0
      },
      airList: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: '',
      handleDetailType: '',
      runningTime: [],
      airDetailForm: {},
      productFormRules: {
        clearanceProject: [
          {
            required: true,
            message: '清场项目不能为空',
            trigger: ['blur', 'change']
          }
        ],
        clearanceResult: [
          {
            required: true,
            message: '清场结果不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      productFormRules2: {
        productName: [
          {
            required: true,
            message: '产品名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        batchNumber: [
          {
            required: true,
            message: '批号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        spec: [
          {
            required: true,
            message: '规格不能为空',
            trigger: ['blur', 'change']
          }
        ],
        process: [
          {
            required: true,
            message: '工序不能为空',
            trigger: ['blur', 'change']
          }
        ],
        clearanceEndTime: [
          {
            required: true,
            message: '清场时间不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      productFormRules3: {
        productionWorkshop: [
          {
            required: true,
            message: '生产车间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        process: [
          {
            required: true,
            message: '工序不能为空',
            trigger: ['blur', 'change']
          }
        ],
        roomNo: [
          {
            required: true,
            message: '房间号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        productName: [
          {
            required: true,
            message: '产品名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        batchNumber: [
          {
            required: true,
            message: '批号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        cleaner: [
          {
            required: true,
            message: '清场人不能为空',
            trigger: ['blur', 'change']
          }
        ],
        clearanceTime: [
          {
            required: true,
            message: '清场时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        checker: [
          {
            required: true,
            message: '检查人不能为空',
            trigger: ['blur', 'change']
          }
        ],
        checkTime: [
          {
            required: true,
            message: '检查时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        signer: [
          {
            required: true,
            message: '签发人不能为空',
            trigger: ['blur', 'change']
          }
        ],
        signTime: [
          {
            required: true,
            message: '签发时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        validPeriod: [
          {
            required: true,
            message: '有效期不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      clearOption: [
        {
          clearanceProject:
            '所有的原辅料、包装材料、中间品、成品、废弃物料是否已清理出生产现场',
          clearanceResult: 1
        },
        {
          clearanceProject:
            '所有与下一批次生产无关的文件、表格、记录等是否已清理出生产现场',
          clearanceResult: 1
        },
        {
          clearanceProject: '房间内生产设备是否已清洁/消毒',
          clearanceResult: 1
        },
        {
          clearanceProject: '生产用工具、器具、容器是否已清洁/消毒并按规定存放',
          clearanceResult: 1
        },
        {
          clearanceProject:
            '地漏、水池、操作台、地面、墙面、门窗、顶棚是否已清洁/消毒',
          clearanceResult: 1
        },
        {
          clearanceProject: '灯罩、排风管道表面、开关箱外壳是否清理/清洁',
          clearanceResult: 1
        }
      ],
      materialProductStockList: []
    }
  },
  watch: {
    runningTime (val, oldVal) {
      this.airForm.clearanceStartTime = val ? val[0] : ''
      this.airForm.clearanceEndTime = val ? val[1] : ''
    },
    airDialogVisible (val) {
      if (!val) {
        this.airForm = {}
        this.runningTime = []
      }
    },
    innerVisible (val) {
      if (!val) {
        this.airDetailForm = {}
      }
    }
  },
  async created () {
    let params = {
      pageNum: 1,
      pageSize: 10
    }
    let airList = await getClearanceList(params)
    this.airList = airList
    selectMaterialProductStockList({ type: 1 }).then(res => {
      this.materialProductStockList = res.list
    })
  },
  methods: {
    airDialogClose () {
      this.$refs.airFormRef.resetFields()
    },
    productFormSubmit () {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === 'add') {
            this.airForm.detailList = JSON.stringify(this.airForm.detailList)
            console.log(this.airForm, 'this.airForm')
            await addClearanceList(this.airForm)
          } else if (this.handleType === 'update') {
            let params = this.airForm
            params.clearanceDetailJson = JSON.stringify(params.detailList || [])
            delete params.detailList
            console.log(params, 'params')
            await updateClearanceDetail(params)
          }
          this.airList = await getClearanceList(this.searchForm)
          this.airDialogVisible = false
        }
      })
    },
    productFormSubmit3 () {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          await generateClearanceList(this.airForm)
          this.airList = await getClearanceList(this.searchForm)
          this.airDialogVisible2 = false
        }
      })
    },
    productFormSubmit2 () {
      this.$refs.productFormRef.validate(async (valid) => {
        this.airDetailForm.clearanceId = this.airForm.id
        if (valid) {
          if (this.handleDetailType === 'add') {
            console.log(this.airDetailForm, 'this.airDetailForm')
            let data = await addClearanceInfoDetail(this.airDetailForm)
            let list = this.airForm.detailList
            list.push(data)
            this.airForm.detailList = list
          } else if (this.handleDetailType === 'update') {
            let list = this.airForm.detailList
            console.log(this.airDetailForm, 'this.airDetailForm')
            let index = this.airDetailForm.index
            delete this.airDetailForm.index
            delete this.airDetailForm.inputType
            console.log(list, 'list')
            console.log(this.airDetailForm, 'this.airDetailForm')
            list.splice(index, 1, this.airDetailForm)
            this.airForm.detailList = list
          }
          this.innerVisible = false
        }
      })
    },
    handleAdd () {
      this.airFormTitle = '新增清场工作记录'
      this.handleType = 'add'
      this.airForm.detailList = JSON.parse(JSON.stringify(this.clearOption))
      this.airDialogVisible = true
    },
    handleAdd2 () {
      this.handleDetailType = 'add'
      this.innerVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteClearanceList(row.id)
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.airList = await getClearanceList(this.searchForm)
      })
    },
    handleDelete2 (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        console.log(row, 'row')
        console.log(index, 'index')
        row.splice(index, 1)
      })
    },
    handleUpdate (index, row) {
      this.airFormTitle = '清场工作记录'
      this.handleType = 'update'
      getClearanceDetail(row.id).then((res) => {
        console.log(res)
        this.airForm = { ...res }
        console.log(this.airForm)
        this.runningTime = [
          res.clearanceStartTime || '',
          res.clearanceEndTime || ''
        ]
        this.airDialogVisible = true
      })
    },
    handleInfo (index, row) {
      this.airFormTitle = '清场工作记录'
      this.handleType = 'detail'
      getClearanceDetail(row.id).then((res) => {
        this.airForm = { ...res }
        console.log(this.airForm)
        this.runningTime = [
          res.clearanceStartTime || '',
          res.clearanceEndTime || ''
        ]
        this.airDialogVisible = true
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      getClearanceList(this.searchForm).then((res) => {
        console.log(res, 'res')
        this.airList = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      getClearanceList(this.searchForm).then((res) => {
        this.airList = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      getClearanceList(this.searchForm).then((res) => {
        this.airList = res
      })
    },
    handleInfo2 (index, row) {
      this.handleDetailType = 'detail'
      let airDetailForm = this.airDetailForm
      airDetailForm = { ...row }
      airDetailForm.inputType = 'detail'
      this.airDetailForm = airDetailForm
      this.innerVisible = true
    },
    handleUpdate2 (index, row) {
      this.handleDetailType = 'update'
      let airDetailForm = this.airDetailForm
      airDetailForm = { ...row }
      airDetailForm.index = index
      airDetailForm.inputType = 'update'
      this.airDetailForm = airDetailForm
      this.innerVisible = true
    },
    handleReview (id, _id) {
      this.$confirm('确认复核通过？', '提示', {
        type: 'warning'
      }).then(async () => {
        let params = {
          id: _id
        }
        await reviewClearanceList(params)
        getClearanceDetail(id).then((res) => {
          this.airForm = { ...res }
          this.runningTime = [
            res.clearanceStartTime || '',
            res.clearanceEndTime || ''
          ]
        })
      })
    },
    handleCheck (id) {
      this.$confirm('确认检查通过？', '提示', {
        type: 'warning'
      }).then(async () => {
        let params = {
          id: id
        }
        await checkClearanceList(params)
        getClearanceDetail(id).then((res) => {
          this.airForm = { ...res }
          this.runningTime = [
            res.clearanceStartTime || '',
            res.clearanceEndTime || ''
          ]
        })
      })
    },
    handleGenerate (id) {
      this.airFormTitle = '新增清场合格证'
      this.handleType = 'add'
      this.airForm.id = id
      this.airDialogVisible2 = true
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 4) {
        const _row = this.flitterData(this.airForm.detailList).one[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex === 5) {
        const _row = this.flitterData(this.airForm.detailList).two[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    flitterData (arr) {
      let spanOneArr = []
      let spanTwoArr = []
      let concatOne = 0
      let concatTwo = 0
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1)
          spanTwoArr.push(1)
        } else {
          if (item.operator === arr[index - 1].operator) {
            //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1
            spanOneArr.push(0)
          } else {
            spanOneArr.push(1)
            concatOne = index
          }
          if (item.reviewer && item.reviewer === arr[index - 1].reviewer) {
            //第二列需合并相同内容的判断条件
            spanTwoArr[concatTwo] += 1
            spanTwoArr.push(0)
          } else {
            spanTwoArr.push(1)
            concatTwo = index
          }
        }
      })
      return {
        one: spanOneArr,
        two: spanTwoArr
      }
    },
    objectSpanMethod2 ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1) {
        return {
          rowspan: 1,
          colspan: 5
        }
      }
    },
    materialProductStockChange (value) {
      if (value) {
        let materialProductStock = this.materialProductStockList.find(item => item.productNo === value)
        this.airForm.productName = materialProductStock.productName
        this.airForm.spec = materialProductStock.spec
      } else {
        this.airForm.productName = ''
        this.airForm.spec = ''
      }
    }
  }
}
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
